import { StyleSheet, View, Text, ScrollView, SafeAreaView, FlatList, Dimensions } from 'react-native';
import { Result, RootStackProps } from '../navigation/types';
import { moderateScale } from 'react-native-size-matters';
import React, {useMemo} from 'react';
import { useSafeAreaInsets } from 'react-native-safe-area-context';


export default function ResultsScreen({ navigation, route: { params } }: RootStackProps<'Results'>) {

  const results: Result[] | undefined = params.results

  const insets = useSafeAreaInsets();
  const usableHeight = Dimensions.get("window").height - insets.top - insets.bottom;
  const usableWidth = Dimensions.get("window").width - insets.left - insets.right;
  const landscape = usableWidth > usableHeight;
  const styles = useMemo(() => getStyles({height: usableHeight, landscape}), [usableHeight, landscape]);

  let numCorrect = 0;
  results?.forEach((result) => {
    if (result.correct) { numCorrect += 1 };
  })

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.resultsText}>{`You scored ${numCorrect} out of 25`}</Text>
      </View>
      <View style={styles.body}>
        <View style={styles.tableRow}>
          <Text style={[styles.textCell, styles.tableHeaderText]}>Question</Text>
          <Text style={[styles.resultsCell, styles.tableHeaderText]}>Your Answer</Text>
          <Text style={[styles.resultsCell, styles.tableHeaderText]}>Correct Answer</Text>
          <Text style={[styles.resultsCell, styles.tableHeaderText]}>Result</Text>
        </View>

        <FlatList
            contentContainerStyle={{ flexGrow: 1 }}
            data={results}
            renderItem={(result) => {const userAnswer = isNaN(result.item.userAnswer!) ? '__' : result.item.userAnswer;
            const mark = result.item.correct ? <Text style={[styles.resultsCell, styles.tableHeaderText, styles.tick]}>✓</Text> : <Text style={[styles.resultsCell, styles.tableHeaderText, styles.cross]}>✗</Text>;
            return (
              <View style={styles.tableRow}>
                <Text style={styles.textCell}>{`${result.item.question}`}</Text>
                <Text style={[styles.resultsCell]}>{userAnswer}</Text>
                <Text style={[styles.resultsCell]}>{result.item.answer}</Text>
                {mark}
              </View>
            );}}
            keyExtractor={item => item.question}
            style={styles.scrollList}
          />
      </View>
    </SafeAreaView>

  );
}

const getStyles = (
  windowSize: { height: number, landscape: boolean },
) => StyleSheet.create({
  container: {
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    flex: 1,
  },
  scrollList: {
    height: '70%',
    maxHeight: windowSize.height * 0.7
  },
  header: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlignVertical: 'center'
  },
  body: {
    flex: 5,
    marginHorizontal: '10%',
  },
  resultsText: {
    width: '100%',
    textAlign: 'center',
    fontSize: moderateScale(24),
    textAlignVertical: 'center',
  },
  tableHeaderText: {
    fontWeight: 'bold',
    fontSize: windowSize.landscape ? moderateScale(24) : moderateScale(12),
  },
  table: {
    justifyContent: 'space-around',
  },
  tableRow: {
    padding: 6,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    borderColor: '#c7c7c7',
    borderWidth: 2,
    textAlignVertical: 'center',
  },
  textCell: {
    flex: 2,
    fontSize: moderateScale(20),
    textAlignVertical: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  resultsCell: {
    flex: 1,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: moderateScale(20),
  },
  tick: {
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: moderateScale(20),
    textAlign: 'center',
    flex: 1,
    zIndex: 2,
    color: 'green'
  },

  cross: {
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: moderateScale(20),
    textAlign: 'center',
    flex: 1,
    zIndex: 2,
    color: 'red'
  }
})