/*
 * Thompson's MTC
 * Copyright © 2022 Vanilla Gorilla Limited
 * HomeScreen.tsx - Home screen for the app.
 */

import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import { QuestionSet, RootStackProps } from "../navigation/types";
import {
  getRandomQuestionSet,
  getTwoFiveTenQuestionSet,
  getSelectionQuestionSet,
} from "../Questions";
import { moderateScale, scale } from "react-native-size-matters";
import { LinearGradient } from "expo-linear-gradient";

const TimeScreen = ({ route, navigation }: RootStackProps<"Time">) => {
  const questionSet: QuestionSet = route.params.questionSet;
  const tables: number[] | undefined = route.params.tables;

  const getQuestions = () => {
    switch (questionSet) {
      case "all": {
        return getRandomQuestionSet();
      }
      case "twoFiveTen": {
        return getTwoFiveTenQuestionSet();
      }
      case "select": {
        return getSelectionQuestionSet(tables as number[]);
      }
    }
  };
  const questions = getQuestions();
  return (
    <View style={styles.content}>
      <View style={styles.heading}>
        <Text style={styles.headerText}>Select Time Per Question</Text>
      </View>
      <View style={styles.buttons_container}>
        <TouchableOpacity
          style={styles.button}
          onPress={(event) => {
            // Focus issue on mac safari meaning the enter button is causing this button to be repressed while on the question screen
            if (Platform.OS === "web") {
              if (event.type === "keyup") {
                return;
              }
            }

            navigation.navigate("Test", { time: 6, questions });
          }}
        >
          <LinearGradient
            colors={["#1387d4", "#259399", "#0b466e"]}
            start={{ x: 0, y: 0 }} // Gradient starting coordinates
            end={{ x: 0, y: 0.5 }} // Gradient ending coordinates
            style={styles.appButtonContainer}
          >
            <Text style={styles.buttonText}>6 Seconds</Text>
          </LinearGradient>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.button}
          onPress={(event) => {
            if (Platform.OS === "web") {
              if (event.type === "keyup") {
                return;
              }
            }
            navigation.navigate("Test", { time: 10, questions });
          }}
        >
          <LinearGradient
            colors={["#1387d4", "#259399", "#0b466e"]}
            start={{ x: 0, y: 0 }} // Gradient starting coordinates
            end={{ x: 0, y: 0.5 }} // Gradient ending coordinates
            style={styles.appButtonContainer}
          >
            <Text style={styles.buttonText}>10 Seconds</Text>
          </LinearGradient>
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.button}
          onPress={(event) => {
            // Focus issue on mac safari meaning the enter button is causing this button to be repressed while on the question screen
            if (Platform.OS === "web") {
              if (event.type === "keyup") {
                return;
              }
            }
            navigation.navigate("Test", { time: 0, questions });
          }}
        >
          <LinearGradient
            colors={["#1387d4", "#259399", "#0b466e"]}
            start={{ x: 0, y: 0 }} // Gradient starting coordinates
            end={{ x: 0, y: 0.5 }} // Gradient ending coordinates
            style={styles.appButtonContainer}
          >
            <Text style={styles.buttonText}>No Time</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default TimeScreen;

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
    backgroundColor: "white",
  },

  heading: {
    flex: 2,
    width: "100%",
    marginBottom: "1%",
    display: "flex",
    flexDirection: "row",
    paddingTop: "5%",
    justifyContent: "center",
    textAlignVertical: "center",
    alignContent: "center",
    alignItems: "center",
  },

  buttons_container: {
    flex: 5,
    width: "100%",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-evenly",
  },

  headerText: {
    fontSize: scale(22),
  },

  appButtonContainer: {
    elevation: 8,
    backgroundColor: "#009688",
    borderColor: "black",
    borderWidth: moderateScale(1),
    borderRadius: moderateScale(10),
    paddingVertical: moderateScale(10),
    paddingHorizontal: moderateScale(12),
  },
  buttonText: {
    fontSize: moderateScale(14),
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
  },
  button: {
    width: "30%",
    //height: '18%'
  },
});
