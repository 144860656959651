/*
 * 1-Minute Maths
 * Copyright © 2021 - 2022 White Rose Maths
 * mathSymbols.ts - constants for math symbols
 */

export const ADD = '+';
export const SUB = '\u2212';
export const DIV = '\u00F7';
export const MULT = '\u00D7';
