export type NonEmptyArray<T> = [T, ...T[]];
import { Dimensions, Platform, PixelRatio } from 'react-native';

/**
 * Produces a range from `from` to `to` inclusive.
 *
 * Note that the step size should divide the difference between `to` and `from`. If it doesn't, the last value in the
 * range will be the last step without going past `to`. E.g. range(0, 5, 2) gives [0, 2, 4].
 *
 * Accepts non-integers, but be wary.
 *
 * @param from The first number in the array.
 * @param to The last number in the array. Allowed to be greater, equal, or less than `to`.
 * @param step The step size (absolute value). Should divide abs(to-from).
 * @returns Array of numbers from `from` to `to` inclusive.
 */
export function range(from: number, to: number, step = 1): NonEmptyArray<number> {
  if (step < 0) { throw new Error(`step size ${step} was not positive`); }
  if (to === from) { return [from]; }

  const signedStep = (to > from) ? step : -step;
  const finishedCondition: (x: number) => boolean = (to > from) ? x => x > to : x => x < to;

  const array = [];
  let x = from;

  while (!finishedCondition(x)) {
    array.push(x);
    x += signedStep;
  }

  return array as NonEmptyArray<number>;
}

/**
 * Get a random element from an array, leaving the array unchanged.
 * Returns null if the input array is empty.
 *
 * The return type might look a little confusing, but basically if the input array was known by typescript to be
 * non-empty (such as for const arrays) then the return type is non-null. Otherwise, it might be null.
 */
 export function getRandomFromArray<A extends readonly unknown[]>(
  input: A
): GetRandomFromArrayReturnType<A> {
  if (input.length === 0) {
    return null as GetRandomFromArrayReturnType<A>;  // Perform a cast - we know what we're doing
  }
  const choice = Math.floor(Math.random() * input.length);
  return input[choice] as GetRandomFromArrayReturnType<A>;  // Perform a cast - we know what we're doing
}

type GetRandomFromArrayReturnType<A extends readonly unknown[]> =
  A extends Readonly<NonEmptyArray<infer T>> ? T
    : A extends Readonly<Array<infer T>> ? T | null
      : never;

/**
 * Get a random sub-array from an array, leaving the array unchanged.
 */
export function getRandomSubArrayFromArray<T>(input: readonly T[], amount? : number): T[] {
  if (input.length === 0) { return []; }

  // Copy because read only
  const copy = [...input];
  // Shuffle array
  const shuffled = shuffle(copy);
  // Get sub-array of at least first 2 elements after shuffled
  if (typeof amount === 'undefined') {
    amount = Math.floor(Math.random() * input.length) + 2;
    amount = amount > input.length ? input.length : amount;
  }

  const selected = shuffled.slice(0, amount);
  return selected;
}

/**
 * Shuffle the order of the elements in an array
 */
export function shuffle<T>(input: readonly T[]): T[] {
  let currentIndex = input.length,  randomIndex;

  // Copy because read only
  const copy = [...input];

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [copy[currentIndex], copy[randomIndex]] = [
      copy[randomIndex], copy[currentIndex]];
  }
  return copy;
}

const { width } = Dimensions.get('window');
// based on iphone 5s's scale
const scale = width / 320;

export function normalize(size: number) {
  const newSize = size * scale;
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  }
  return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
}