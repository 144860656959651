/*
 * Thompson's MTC
 * Copyright © 2022 Vanilla Gorilla Limited
 * SelectScreen.tsx - Home screen for the app.
 */

import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
  FlatList,
  Dimensions,
} from "react-native";
import { RootStackProps } from "../navigation/types";
import { moderateScale, scale } from "react-native-size-matters";
import { LinearGradient } from "expo-linear-gradient";
import { useState } from "react";

const height = Dimensions.get("window").height * 0.1;
const buttonHeight = height * 0.8;
const width = Dimensions.get("window").width * 0.2;

const tables = [
  {
    id: 2,
    table: "2",
    selected: false,
  },
  {
    id: 3,
    table: "3",
    selected: false,
  },
  {
    id: 4,
    table: "4",
    selected: false,
  },
  {
    id: 5,
    table: "5",
    selected: false,
  },
  {
    id: 6,
    table: "6",
  },
  {
    id: 7,
    table: "7",
    selected: false,
  },
  {
    id: 8,
    table: "8",
    selected: false,
  },
  {
    id: 9,
    table: "9",
    selected: false,
  },
  {
    id: 10,
    table: "10",
    selected: false,
  },
  {
    id: 11,
    table: "11",
    selected: false,
  },
  {
    id: 12,
    table: "12",
    selected: false,
  },
];

const SelectScreen = ({ navigation }: RootStackProps<"Select">) => {
  const [data, setData] = useState(tables);

  const onPressHandler = (id: number) => {
    let renderData = [...data];
    renderData[id - 2].selected = !renderData[id - 2].selected;
    setData(renderData);
  };

  return (
    <View style={styles.content}>
      <View style={styles.heading}>
        <Text style={styles.headerText}>
          Select which tables you want to practice
        </Text>
      </View>
      <View style={styles.buttons_container}>
        <FlatList
          numColumns={3}
          data={data}
          keyExtractor={(item) => item.id.toString()}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => (
            <TouchableOpacity
              onPress={() => onPressHandler(item.id)}
              style={[
                {
                  flex: 1,
                  height: height,
                  alignContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                },
              ]}
            >
              <View
                style={[
                  item.selected == true
                    ? {
                        padding: 10,
                        borderRadius: 5,
                        width: width,
                        backgroundColor: "#259399",
                        borderColor: "#000",
                        borderWidth: 2,
                      }
                    : {
                        padding: 10,
                        borderRadius: 5,
                        borderColor: "#000",
                        borderWidth: 2,

                        width: width,
                      },
                  {
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    height: buttonHeight,
                  },
                ]}
              >
                <Text style={[styles.buttonText, { color: "black" }]}>
                  {item.table}
                </Text>
              </View>
            </TouchableOpacity>
          )}
        />
      </View>
      <View style={styles.next_button_container}>
        <TouchableOpacity
          style={styles.button}
          onPress={(event) => {
            // Focus issue on mac safari meaning the enter button is causing this button to be repressed while on the question screen
            if (Platform.OS === "web") {
              if (event.type === "keyup") {
                return;
              }
            }
            const tables = [];
            for (let table of data) {
              if (table.selected) {
                tables.push(table.id);
              }
            }
            navigation.navigate("Time", { questionSet: "select", tables });
          }}
          disabled={
            data.filter((table) => table.selected === true).length === 0
          }
        >
          <LinearGradient
            colors={["#1387d4", "#259399", "#0b466e"]}
            start={{ x: 0, y: 0 }} // Gradient starting coordinates
            end={{ x: 0, y: 0.5 }} // Gradient ending coordinates
            style={styles.appButtonContainer}
          >
            <Text style={styles.buttonText}>Next</Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default SelectScreen;

const styles = StyleSheet.create({
  content: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    margin: 0,
    padding: 0,
    backgroundColor: "white",
  },

  heading: {
    flex: 1.5,
    width: "100%",
    marginBottom: "1%",
    display: "flex",
    flexDirection: "row",
    paddingTop: "5%",
    justifyContent: "center",
    textAlignVertical: "center",
    textAlign: "center",
    alignContent: "center",
    alignItems: "center",
  },

  buttons_container: {
    flex: 5,
    width: "100%",
    flexDirection: "column",
    alignContent: "center",
    justifyContent: "space-evenly",
  },

  next_button_container: {
    flex: 2,
    width: "100%",
    flexDirection: "row",
    alignContent: "center",
    // alignItems: "center",
    justifyContent: "space-evenly",
  },

  headerText: {
    fontSize: scale(22),
  },

  appButtonContainer: {
    elevation: 8,
    backgroundColor: "#009688",
    borderColor: "black",
    borderWidth: moderateScale(1),
    borderRadius: moderateScale(10),
    paddingVertical: moderateScale(10),
    paddingHorizontal: moderateScale(12),
  },
  buttonText: {
    fontSize: moderateScale(14),
    color: "#fff",
    fontWeight: "bold",
    alignSelf: "center",
  },
  button: {
    width: "30%",
    //height: '18%'
  },
});
