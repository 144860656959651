/*
 * Thompson's MTC
 * Copyright © 2022 Vanilla Gorilla Limited
 */

import { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Question, Result, RootStackProps } from '../navigation/types';
import QuestionComponent from './Question';

const TestScreen = ({ route, navigation }: RootStackProps<'Test'>) => {
  
  const questions: Question[] = route.params.questions;
  
  const numberOfQuestions = 25;
  
  const [questionNumber, setQuestionNumber] = useState<number>(1);
  const [results, setResults] = useState<Result[]>();

  const addResult = (question: string, answer: Question['answer'], userAnswer: Question['answer'], correct: boolean ) => {

    const result: Result = {question, answer, userAnswer, correct}; 
    setResults((results) => {
      if(results !== undefined){
        return [...results, result];
      }
      else {
        return [result];
      }
    })

    nextQuestion();
  }

  const complete = () => {
    // Remove ourselves and navigate to the results screen.
    const state = navigation.getState();
    if (state.routes[state.index].name !== route.name) {
      // We've hit an edge-case where the user has navigated away but the timer hasn't been cancelled yet.
      // Don't navigate anywhere
    } else {
      navigation.replace('Results', {results: results});
    }
  }

  useEffect(() => {
    if(questionNumber > numberOfQuestions){
      complete();
      return;
    }
  }, [questionNumber]);

  const nextQuestion = () => {
    setQuestionNumber(questionNumber => { return questionNumber += 1 });
  }

  return (
    <View style={styles.container}>
      <QuestionComponent question={questions[questionNumber-1]} onQuestionAnswered={addResult} questionTime={route.params.time} questionNumber={questionNumber} key={questionNumber}/>
    </View>
  );
  
};

export default TestScreen;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    backgroundColor: 'white'
  },
}); 
