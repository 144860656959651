/*
 * 1-Minute Maths
 * Copyright © 2021 - 2022 White Rose Maths
 * QuestionComponent.tsx - Component for rendering the question and handling input
 */

import { StyleSheet, View, Text, Dimensions } from "react-native";
import Numpad from "./NumPad";
import { useCallback, useEffect, useState } from "react";
import QuestionStatement from "./QuestionStatement";
import { Question } from "../Questions";
import { MULT } from "../mathSymbols";
import { moderateScale } from "react-native-size-matters";

/**
 * General component which can display a question to the user, and accept the user's input.
 *
 * Is responsible for validating the user's answer and using the provided callback.
 *
 * Does support being reused for a new question, since it is simple to reset this state. However, the question object
 * must change identity (i.e. old !== new).
 */

let questionTimer: NodeJS.Timer | undefined;

const QuestionComponent = (props: {
  question: Question;
  onQuestionAnswered: (
    question: string,
    answer: Question["answer"],
    userAnswer: Question["answer"],
    correct: boolean
  ) => void;
  questionNumber: number;
  questionTime: number;
}) => {
  const { question, onQuestionAnswered, questionTime, questionNumber } = props;
  const rest = 3;
  const totalTime = questionTime === 0 ? -1 : questionTime + rest;

  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;

  const landscape = windowWidth > windowHeight;

  const [questionTimerCounter, setQuestionTimerCounter] = useState<number>(0);

  // User input
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [numpadInput, setNumpadInput] = useState("");

  // Reset state if the question changes
  useEffect(() => {
    setButtonsDisabled(false);
    setNumpadInput("");
    questionTimer = setInterval(questionTick, 1000);
    return () => {
      clearInterval(questionTimer);
      questionTimer = undefined;
    };
  }, [question]);

  const questionTick = () => {
    setQuestionTimerCounter((counter) => {
      return (counter += 1);
    });
  };

  const submitAnswer = useCallback(
    (question: Question, userAnswer: Question["answer"]) => {
      setButtonsDisabled(true);
      setQuestionTimerCounter(0);
      const isCorrect = question.answer === userAnswer;
      const questionString = `${question.numA} ${MULT} ${question.numB}`;
      onQuestionAnswered(
        questionString,
        question.answer,
        userAnswer,
        isCorrect
      );
    },
    [question, onQuestionAnswered]
  );

  if (totalTime - questionTimerCounter === 0) {
    submitAnswer(question, parseInt(numpadInput));
  }

  // Callbacks for input types (need to be defined unconditionally since they are hooks)
  const handleNumpadInput = useCallback(
    (input: string) => setNumpadInput((old) => old + input),
    []
  );
  const handleNumpadBackspace = useCallback(
    () => setNumpadInput((old) => old.slice(0, -1)),
    []
  );
  const handleNumpadConfirm = useCallback(() => {
    if (numpadInput) {
      submitAnswer(question, parseInt(numpadInput));
    }
  }, [numpadInput, submitAnswer]);

  const restComp = (
    <View style={styles.restTextContainer}>
      <Text style={styles.restText}>Rest: {rest - questionTimerCounter}s</Text>
    </View>
  );
  const questionComp = (
    <>
      <View style={styles.header}>
        <Text style={styles.questionNumberText}>
          Question Number: {questionNumber}
        </Text>
        <Text style={styles.timeText}>
          Remaining Time: {totalTime - questionTimerCounter}s
        </Text>
      </View>
      <View style={styles.questionView}>
        <QuestionStatement question={question} userAnswer={numpadInput} />
      </View>
      <View style={styles.pad_view}>
        {landscape ? (
          <>
            <View style={styles.leftContent}></View>
            <View style={styles.rightContent}>
              <Numpad
                handleInputEvent={handleNumpadInput}
                handleBackspaceEvent={handleNumpadBackspace}
                handleConfirmEvent={handleNumpadConfirm}
                disabled={buttonsDisabled}
              />
            </View>
          </>
        ) : (
          <View style={styles.centerContent}>
            <Numpad
              handleInputEvent={handleNumpadInput}
              handleBackspaceEvent={handleNumpadBackspace}
              handleConfirmEvent={handleNumpadConfirm}
              disabled={buttonsDisabled}
            />
          </View>
        )}
      </View>
    </>
  );

  const displayComp = questionTimerCounter >= rest ? questionComp : restComp;

  return (
    <View style={styles.container}>
      <View style={styles.content}>{displayComp}</View>
    </View>
  );
};

export default QuestionComponent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "stretch",
  },

  content: {
    flex: 1,
    flexDirection: "column",
  },

  pad_view: {
    flex: 4,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },

  questionView: {
    flex: 1.5,
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },

  header: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
  },

  leftContent: {
    flex: 1,
    justifyContent: "flex-end",
    flexDirection: "row",
  },

  rightContent: {
    flex: 1,
    justifyContent: "flex-start",
    flexDirection: "row",
    height: "100%",
  },

  centerContent: {
    flex: 1,
    justifyContent: "center",
    flexDirection: "row",
    height: "100%",
  },

  restText: {
    fontSize: moderateScale(22),
    alignSelf: "center",
    textAlignVertical: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  restTextContainer: {
    fontSize: moderateScale(22),
    alignSelf: "center",
    height: "50%",
    textAlignVertical: "center",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },

  timeText: {
    fontSize: moderateScale(16),
    paddingRight: moderateScale(10),
  },

  questionNumberText: {
    fontSize: moderateScale(16),
    paddingLeft: moderateScale(10),
  },
});
