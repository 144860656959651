/*
 * 1-Minute Maths
 * Copyright © 2021 - 2022 White Rose Maths
 * QuestionStatement.tsx - Component to render the statement part of a question
 */

import { Platform, Text, View, StyleSheet, Dimensions } from 'react-native';
import { MULT } from '../mathSymbols';
import { Question } from '../navigation/types';
import { moderateScale } from 'react-native-size-matters';
import { useMemo } from 'react';

const QuestionStatement = ( props: { question: Question, userAnswer: string }): JSX.Element => {

  const {question, userAnswer} = props;
  const ans = userAnswer !== '' ? userAnswer : (Platform.OS === 'ios' ? '  ' : '   ');

  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const landscape = windowWidth > windowHeight;
  const styles = useMemo(() => getStyles({landscape}), [landscape]);

  if (question === undefined || question === null) {
    return (
      <View style={styles.questionView}>
        <Text allowFontScaling={false} style={styles.text}>Loading Question</Text>
      </View>
    );
  }

  // Order of array passed to `equation` is important
  return (
    <View style={styles.questionView}>
      <View style={styles.leftContent}>
        <Text allowFontScaling={false} style={styles.text} key="numA">{question.numA}</Text>
        <Text allowFontScaling={false} style={styles.text} key="sign">{MULT}</Text>
        <Text allowFontScaling={false} style={styles.text} key="numB">{question.numB}</Text>
        <Text allowFontScaling={false} style={styles.text} key="eq">=</Text>
      </View>
      <View style={styles.rightContent}>
        <Text allowFontScaling={false} style={[styles.text, styles.answer]} key="answer">{ans}</Text>
      </View>
    </View>
  );

};

export default QuestionStatement;

const getStyles = (
  orientation: { landscape: boolean },
) => StyleSheet.create({
  questionView: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    flex: 1,
    textAlign: 'center',
    padding: 0,
    marginTop: 0
  },

  text: {
    fontSize: orientation.landscape ? moderateScale(48) : moderateScale(40),
    marginLeft: orientation.landscape ? moderateScale(7) : moderateScale(3),
    marginRight:orientation.landscape ? moderateScale(7) : moderateScale(3),
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    textAlignVertical: 'center'
  },

  answer: {
    borderColor: '#f6c349',
    borderWidth: moderateScale(3),
    backgroundColor: 'white',
    width:'70%'
  },

  leftContent: {
    flex: 1,
    justifyContent: 'flex-end',
    flexDirection: 'row',    
  },

  rightContent: {
    flex: 1,
    justifyContent: 'flex-start',
    flexDirection: 'row',
  }

});
