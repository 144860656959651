import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  shuffle,
} from "./utils";

export interface Question {
  readonly numA: number;
  readonly numB: number;
  readonly answer: number;
}

export const twoTimes: Question[] = [
  { numA: 2, numB: 2, answer: 4 },
  { numA: 2, numB: 3, answer: 6 },
  { numA: 2, numB: 4, answer: 8 },
  { numA: 2, numB: 5, answer: 10 },
  { numA: 2, numB: 6, answer: 12 },
  { numA: 2, numB: 7, answer: 14 },
  { numA: 2, numB: 8, answer: 16 },
  { numA: 2, numB: 9, answer: 18 },
  { numA: 2, numB: 10, answer: 20 },
  { numA: 2, numB: 11, answer: 22 },
  { numA: 2, numB: 12, answer: 24 },
];

export const threeTimes: Question[] = [
  { numA: 3, numB: 2, answer: 6 },
  { numA: 3, numB: 3, answer: 9 },
  { numA: 3, numB: 4, answer: 12 },
  { numA: 3, numB: 5, answer: 15 },
  { numA: 3, numB: 6, answer: 18 },
  { numA: 3, numB: 7, answer: 21 },
  { numA: 3, numB: 8, answer: 24 },
  { numA: 3, numB: 9, answer: 27 },
  { numA: 3, numB: 10, answer: 30 },
  { numA: 3, numB: 11, answer: 33 },
  { numA: 3, numB: 12, answer: 36 },
];

export const fourTimes: Question[] = [
  { numA: 4, numB: 2, answer: 8 },
  { numA: 4, numB: 3, answer: 12 },
  { numA: 4, numB: 4, answer: 16 },
  { numA: 4, numB: 5, answer: 20 },
  { numA: 4, numB: 6, answer: 24 },
  { numA: 4, numB: 7, answer: 28 },
  { numA: 4, numB: 8, answer: 32 },
  { numA: 4, numB: 9, answer: 36 },
  { numA: 4, numB: 10, answer: 40 },
  { numA: 4, numB: 11, answer: 44 },
  { numA: 4, numB: 12, answer: 48 },
];

export const fiveTimes: Question[] = [
  { numA: 5, numB: 2, answer: 10 },
  { numA: 5, numB: 3, answer: 15 },
  { numA: 5, numB: 4, answer: 20 },
  { numA: 5, numB: 5, answer: 25 },
  { numA: 5, numB: 6, answer: 30 },
  { numA: 5, numB: 7, answer: 35 },
  { numA: 5, numB: 8, answer: 40 },
  { numA: 5, numB: 9, answer: 45 },
  { numA: 5, numB: 10, answer: 50 },
  { numA: 5, numB: 11, answer: 55 },
  { numA: 5, numB: 12, answer: 60 },
];

export const sixTimes: Question[] = [
  { numA: 6, numB: 2, answer: 12 },
  { numA: 6, numB: 3, answer: 18 },
  { numA: 6, numB: 4, answer: 24 },
  { numA: 6, numB: 5, answer: 30 },
  { numA: 6, numB: 6, answer: 36 },
  { numA: 6, numB: 7, answer: 42 },
  { numA: 6, numB: 8, answer: 48 },
  { numA: 6, numB: 9, answer: 54 },
  { numA: 6, numB: 10, answer: 60 },
  { numA: 6, numB: 11, answer: 66 },
  { numA: 6, numB: 12, answer: 72 },
];

export const sevenTimes: Question[] = [
  { numA: 7, numB: 2, answer: 14 },
  { numA: 7, numB: 3, answer: 21 },
  { numA: 7, numB: 4, answer: 28 },
  { numA: 7, numB: 5, answer: 35 },
  { numA: 7, numB: 6, answer: 42 },
  { numA: 7, numB: 7, answer: 49 },
  { numA: 7, numB: 8, answer: 56 },
  { numA: 7, numB: 9, answer: 63 },
  { numA: 7, numB: 10, answer: 70 },
  { numA: 7, numB: 11, answer: 77 },
  { numA: 7, numB: 12, answer: 84 },
];

export const eightTimes: Question[] = [
  { numA: 8, numB: 2, answer: 16 },
  { numA: 8, numB: 3, answer: 24 },
  { numA: 8, numB: 4, answer: 32 },
  { numA: 8, numB: 5, answer: 40 },
  { numA: 8, numB: 6, answer: 48 },
  { numA: 8, numB: 7, answer: 56 },
  { numA: 8, numB: 8, answer: 64 },
  { numA: 8, numB: 9, answer: 72 },
  { numA: 8, numB: 10, answer: 80 },
  { numA: 8, numB: 11, answer: 88 },
  { numA: 8, numB: 12, answer: 96 },
];

export const nineTimes: Question[] = [
  { numA: 9, numB: 2, answer: 18 },
  { numA: 9, numB: 3, answer: 27 },
  { numA: 9, numB: 4, answer: 36 },
  { numA: 9, numB: 5, answer: 45 },
  { numA: 9, numB: 6, answer: 54 },
  { numA: 9, numB: 7, answer: 63 },
  { numA: 9, numB: 8, answer: 72 },
  { numA: 9, numB: 9, answer: 81 },
  { numA: 9, numB: 10, answer: 90 },
  { numA: 9, numB: 11, answer: 99 },
  { numA: 9, numB: 12, answer: 108 },
];

export const tenTimes: Question[] = [
  { numA: 10, numB: 2, answer: 20 },
  { numA: 10, numB: 3, answer: 30 },
  { numA: 10, numB: 4, answer: 40 },
  { numA: 10, numB: 5, answer: 50 },
  { numA: 10, numB: 6, answer: 60 },
  { numA: 10, numB: 7, answer: 70 },
  { numA: 10, numB: 8, answer: 80 },
  { numA: 10, numB: 9, answer: 90 },
  { numA: 10, numB: 10, answer: 100 },
  { numA: 10, numB: 11, answer: 110 },
  { numA: 10, numB: 12, answer: 120 },
];

export const elevenTimes: Question[] = [
  { numA: 11, numB: 2, answer: 22 },
  { numA: 11, numB: 3, answer: 33 },
  { numA: 11, numB: 4, answer: 44 },
  { numA: 11, numB: 5, answer: 55 },
  { numA: 11, numB: 6, answer: 66 },
  { numA: 11, numB: 7, answer: 77 },
  { numA: 11, numB: 8, answer: 88 },
  { numA: 11, numB: 9, answer: 99 },
  { numA: 11, numB: 10, answer: 110 },
  { numA: 11, numB: 11, answer: 121 },
  { numA: 11, numB: 12, answer: 132 },
];

export const twelveTimes: Question[] = [
  { numA: 12, numB: 2, answer: 24 },
  { numA: 12, numB: 3, answer: 36 },
  { numA: 12, numB: 4, answer: 48 },
  { numA: 12, numB: 5, answer: 60 },
  { numA: 12, numB: 6, answer: 72 },
  { numA: 12, numB: 7, answer: 84 },
  { numA: 12, numB: 8, answer: 96 },
  { numA: 12, numB: 9, answer: 108 },
  { numA: 12, numB: 10, answer: 120 },
  { numA: 12, numB: 11, answer: 132 },
  { numA: 12, numB: 12, answer: 144 },
];

export const collection: { questions: Question[]; num: number }[] = [
  { questions: twoTimes, num: 1 },
  { questions: threeTimes, num: 2 },
  { questions: fourTimes, num: 2 },
  { questions: fiveTimes, num: 2 },
  { questions: sixTimes, num: 3 },
  { questions: sevenTimes, num: 3 },
  { questions: eightTimes, num: 3 },
  { questions: nineTimes, num: 3 },
  { questions: tenTimes, num: 1 },
  { questions: elevenTimes, num: 2 },
  { questions: twelveTimes, num: 3 },
];

export const twoFAndBTimes: Question[] = [
  { numA: 2, numB: 2, answer: 4 },
  { numA: 2, numB: 3, answer: 6 },
  { numA: 2, numB: 4, answer: 8 },
  { numA: 2, numB: 5, answer: 10 },
  { numA: 2, numB: 6, answer: 12 },
  { numA: 2, numB: 7, answer: 14 },
  { numA: 2, numB: 8, answer: 16 },
  { numA: 2, numB: 9, answer: 18 },
  { numA: 2, numB: 10, answer: 20 },
  { numA: 2, numB: 11, answer: 22 },
  { numB: 2, numA: 12, answer: 24 },
  { numB: 2, numA: 2, answer: 4 },
  { numB: 2, numA: 3, answer: 6 },
  { numB: 2, numA: 4, answer: 8 },
  { numB: 2, numA: 5, answer: 10 },
  { numB: 2, numA: 6, answer: 12 },
  { numB: 2, numA: 7, answer: 14 },
  { numB: 2, numA: 8, answer: 16 },
  { numB: 2, numA: 9, answer: 18 },
  { numB: 2, numA: 10, answer: 20 },
  { numB: 2, numA: 11, answer: 22 },
  { numB: 2, numA: 12, answer: 24 },
];

export const threeFAndBTimes: Question[] = [
  { numA: 3, numB: 2, answer: 6 },
  { numA: 3, numB: 3, answer: 9 },
  { numA: 3, numB: 4, answer: 12 },
  { numA: 3, numB: 5, answer: 15 },
  { numA: 3, numB: 6, answer: 18 },
  { numA: 3, numB: 7, answer: 21 },
  { numA: 3, numB: 8, answer: 24 },
  { numA: 3, numB: 9, answer: 27 },
  { numA: 3, numB: 10, answer: 30 },
  { numA: 3, numB: 11, answer: 33 },
  { numB: 3, numA: 12, answer: 36 },
  { numB: 3, numA: 2, answer: 6 },
  { numB: 3, numA: 3, answer: 9 },
  { numB: 3, numA: 4, answer: 12 },
  { numB: 3, numA: 5, answer: 15 },
  { numB: 3, numA: 6, answer: 18 },
  { numB: 3, numA: 7, answer: 21 },
  { numB: 3, numA: 8, answer: 24 },
  { numB: 3, numA: 9, answer: 27 },
  { numB: 3, numA: 10, answer: 30 },
  { numB: 3, numA: 11, answer: 33 },
  { numB: 3, numA: 12, answer: 36 },
];

export const fourFAndBTimes: Question[] = [
  { numA: 4, numB: 2, answer: 8 },
  { numA: 4, numB: 3, answer: 12 },
  { numA: 4, numB: 4, answer: 16 },
  { numA: 4, numB: 5, answer: 20 },
  { numA: 4, numB: 6, answer: 24 },
  { numA: 4, numB: 7, answer: 28 },
  { numA: 4, numB: 8, answer: 32 },
  { numA: 4, numB: 9, answer: 36 },
  { numA: 4, numB: 10, answer: 40 },
  { numA: 4, numB: 11, answer: 44 },
  { numB: 4, numA: 12, answer: 48 },
  { numB: 4, numA: 2, answer: 8 },
  { numB: 4, numA: 3, answer: 12 },
  { numB: 4, numA: 4, answer: 16 },
  { numB: 4, numA: 5, answer: 20 },
  { numB: 4, numA: 6, answer: 24 },
  { numB: 4, numA: 7, answer: 28 },
  { numB: 4, numA: 8, answer: 32 },
  { numB: 4, numA: 9, answer: 36 },
  { numB: 4, numA: 10, answer: 40 },
  { numB: 4, numA: 11, answer: 44 },
  { numB: 4, numA: 12, answer: 48 },
];

export const fiveFAndBTimes: Question[] = [
  { numA: 5, numB: 2, answer: 10 },
  { numA: 5, numB: 3, answer: 15 },
  { numA: 5, numB: 4, answer: 20 },
  { numA: 5, numB: 5, answer: 25 },
  { numA: 5, numB: 6, answer: 30 },
  { numA: 5, numB: 7, answer: 35 },
  { numA: 5, numB: 8, answer: 40 },
  { numA: 5, numB: 9, answer: 45 },
  { numA: 5, numB: 10, answer: 50 },
  { numA: 5, numB: 11, answer: 55 },
  { numA: 5, numB: 12, answer: 60 },
  { numB: 5, numA: 2, answer: 10 },
  { numB: 5, numA: 3, answer: 15 },
  { numB: 5, numA: 4, answer: 20 },
  { numB: 5, numA: 5, answer: 25 },
  { numB: 5, numA: 6, answer: 30 },
  { numB: 5, numA: 7, answer: 35 },
  { numB: 5, numA: 8, answer: 40 },
  { numB: 5, numA: 9, answer: 45 },
  { numB: 5, numA: 10, answer: 50 },
  { numB: 5, numA: 11, answer: 55 },
  { numB: 5, numA: 12, answer: 60 },
];

export const sixFAndBTimes: Question[] = [
  { numA: 6, numB: 2, answer: 12 },
  { numA: 6, numB: 3, answer: 18 },
  { numA: 6, numB: 4, answer: 24 },
  { numA: 6, numB: 5, answer: 30 },
  { numA: 6, numB: 6, answer: 36 },
  { numA: 6, numB: 7, answer: 42 },
  { numA: 6, numB: 8, answer: 48 },
  { numA: 6, numB: 9, answer: 54 },
  { numA: 6, numB: 10, answer: 60 },
  { numA: 6, numB: 11, answer: 66 },
  { numA: 6, numB: 12, answer: 72 },
  { numB: 6, numA: 2, answer: 12 },
  { numB: 6, numA: 3, answer: 18 },
  { numB: 6, numA: 4, answer: 24 },
  { numB: 6, numA: 5, answer: 30 },
  { numB: 6, numA: 6, answer: 36 },
  { numB: 6, numA: 7, answer: 42 },
  { numB: 6, numA: 8, answer: 48 },
  { numB: 6, numA: 9, answer: 54 },
  { numB: 6, numA: 10, answer: 60 },
  { numB: 6, numA: 11, answer: 66 },
  { numB: 6, numA: 12, answer: 72 },
];

export const sevenFAndBTimes: Question[] = [
  { numA: 7, numB: 2, answer: 14 },
  { numA: 7, numB: 3, answer: 21 },
  { numA: 7, numB: 4, answer: 28 },
  { numA: 7, numB: 5, answer: 35 },
  { numA: 7, numB: 6, answer: 42 },
  { numA: 7, numB: 7, answer: 49 },
  { numA: 7, numB: 8, answer: 56 },
  { numA: 7, numB: 9, answer: 63 },
  { numA: 7, numB: 10, answer: 70 },
  { numA: 7, numB: 11, answer: 77 },
  { numA: 7, numB: 12, answer: 84 },
  { numB: 7, numA: 2, answer: 14 },
  { numB: 7, numA: 3, answer: 21 },
  { numB: 7, numA: 4, answer: 28 },
  { numB: 7, numA: 5, answer: 35 },
  { numB: 7, numA: 6, answer: 42 },
  { numB: 7, numA: 7, answer: 49 },
  { numB: 7, numA: 8, answer: 56 },
  { numB: 7, numA: 9, answer: 63 },
  { numB: 7, numA: 10, answer: 70 },
  { numB: 7, numA: 11, answer: 77 },
  { numB: 7, numA: 12, answer: 84 },
];

export const eightFAndBTimes: Question[] = [
  { numA: 8, numB: 2, answer: 16 },
  { numA: 8, numB: 3, answer: 24 },
  { numA: 8, numB: 4, answer: 32 },
  { numA: 8, numB: 5, answer: 40 },
  { numA: 8, numB: 6, answer: 48 },
  { numA: 8, numB: 7, answer: 56 },
  { numA: 8, numB: 8, answer: 64 },
  { numA: 8, numB: 9, answer: 72 },
  { numA: 8, numB: 10, answer: 80 },
  { numA: 8, numB: 11, answer: 88 },
  { numA: 8, numB: 12, answer: 96 },
  { numB: 8, numA: 2, answer: 16 },
  { numB: 8, numA: 3, answer: 24 },
  { numB: 8, numA: 4, answer: 32 },
  { numB: 8, numA: 5, answer: 40 },
  { numB: 8, numA: 6, answer: 48 },
  { numB: 8, numA: 7, answer: 56 },
  { numB: 8, numA: 8, answer: 64 },
  { numB: 8, numA: 9, answer: 72 },
  { numB: 8, numA: 10, answer: 80 },
  { numB: 8, numA: 11, answer: 88 },
  { numB: 8, numA: 12, answer: 96 },
];

export const nineFAndBTimes: Question[] = [
  { numA: 9, numB: 2, answer: 18 },
  { numA: 9, numB: 3, answer: 27 },
  { numA: 9, numB: 4, answer: 36 },
  { numA: 9, numB: 5, answer: 45 },
  { numA: 9, numB: 6, answer: 54 },
  { numA: 9, numB: 7, answer: 63 },
  { numA: 9, numB: 8, answer: 72 },
  { numA: 9, numB: 9, answer: 81 },
  { numA: 9, numB: 10, answer: 90 },
  { numA: 9, numB: 11, answer: 99 },
  { numA: 9, numB: 12, answer: 108 },
  { numB: 9, numA: 2, answer: 18 },
  { numB: 9, numA: 3, answer: 27 },
  { numB: 9, numA: 4, answer: 36 },
  { numB: 9, numA: 5, answer: 45 },
  { numB: 9, numA: 6, answer: 54 },
  { numB: 9, numA: 7, answer: 63 },
  { numB: 9, numA: 8, answer: 72 },
  { numB: 9, numA: 9, answer: 81 },
  { numB: 9, numA: 10, answer: 90 },
  { numB: 9, numA: 11, answer: 99 },
  { numB: 9, numA: 12, answer: 108 },
];

export const tenFAndBTimes: Question[] = [
  { numA: 10, numB: 2, answer: 20 },
  { numA: 10, numB: 3, answer: 30 },
  { numA: 10, numB: 4, answer: 40 },
  { numA: 10, numB: 5, answer: 50 },
  { numA: 10, numB: 6, answer: 60 },
  { numA: 10, numB: 7, answer: 70 },
  { numA: 10, numB: 8, answer: 80 },
  { numA: 10, numB: 9, answer: 90 },
  { numA: 10, numB: 10, answer: 100 },
  { numA: 10, numB: 11, answer: 110 },
  { numA: 10, numB: 12, answer: 120 },
  { numB: 10, numA: 2, answer: 20 },
  { numB: 10, numA: 3, answer: 30 },
  { numB: 10, numA: 4, answer: 40 },
  { numB: 10, numA: 5, answer: 50 },
  { numB: 10, numA: 6, answer: 60 },
  { numB: 10, numA: 7, answer: 70 },
  { numB: 10, numA: 8, answer: 80 },
  { numB: 10, numA: 9, answer: 90 },
  { numB: 10, numA: 10, answer: 100 },
  { numB: 10, numA: 11, answer: 110 },
  { numB: 10, numA: 12, answer: 120 },
];

export const elevenFAndBTimes: Question[] = [
  { numA: 11, numB: 2, answer: 22 },
  { numA: 11, numB: 3, answer: 33 },
  { numA: 11, numB: 4, answer: 44 },
  { numA: 11, numB: 5, answer: 55 },
  { numA: 11, numB: 6, answer: 66 },
  { numA: 11, numB: 7, answer: 77 },
  { numA: 11, numB: 8, answer: 88 },
  { numA: 11, numB: 9, answer: 99 },
  { numA: 11, numB: 10, answer: 110 },
  { numA: 11, numB: 11, answer: 121 },
  { numA: 11, numB: 12, answer: 132 },
  { numB: 11, numA: 2, answer: 22 },
  { numB: 11, numA: 3, answer: 33 },
  { numB: 11, numA: 4, answer: 44 },
  { numB: 11, numA: 5, answer: 55 },
  { numB: 11, numA: 6, answer: 66 },
  { numB: 11, numA: 7, answer: 77 },
  { numB: 11, numA: 8, answer: 88 },
  { numB: 11, numA: 9, answer: 99 },
  { numB: 11, numA: 10, answer: 110 },
  { numB: 11, numA: 11, answer: 121 },
  { numB: 11, numA: 12, answer: 132 },
];

export const twelveFAndBTimes: Question[] = [
  { numA: 12, numB: 2, answer: 24 },
  { numA: 12, numB: 3, answer: 36 },
  { numA: 12, numB: 4, answer: 48 },
  { numA: 12, numB: 5, answer: 60 },
  { numA: 12, numB: 6, answer: 72 },
  { numA: 12, numB: 7, answer: 84 },
  { numA: 12, numB: 8, answer: 96 },
  { numA: 12, numB: 9, answer: 108 },
  { numA: 12, numB: 10, answer: 120 },
  { numA: 12, numB: 11, answer: 132 },
  { numA: 12, numB: 12, answer: 144 },
  { numB: 12, numA: 2, answer: 24 },
  { numB: 12, numA: 3, answer: 36 },
  { numB: 12, numA: 4, answer: 48 },
  { numB: 12, numA: 5, answer: 60 },
  { numB: 12, numA: 6, answer: 72 },
  { numB: 12, numA: 7, answer: 84 },
  { numB: 12, numA: 8, answer: 96 },
  { numB: 12, numA: 9, answer: 108 },
  { numB: 12, numA: 10, answer: 120 },
  { numB: 12, numA: 11, answer: 132 },
  { numB: 12, numA: 12, answer: 144 },
];

export const twoFiveTensTimes: { questions: Question[]; num: number }[] = [
  { questions: twoFAndBTimes, num: 8 },
  { questions: fiveFAndBTimes, num: 9 },
  { questions: tenFAndBTimes, num: 8 },
];

export const selectTimes: { questions: Question[]; id: number }[] = [
  { questions: twoFAndBTimes, id: 2 },
  { questions: threeFAndBTimes, id: 3 },
  { questions: fourFAndBTimes, id: 4 },
  { questions: fiveFAndBTimes, id: 5 },
  { questions: sixFAndBTimes, id: 6 },
  { questions: sevenFAndBTimes, id: 7 },
  { questions: eightFAndBTimes, id: 8 },
  { questions: nineFAndBTimes, id: 9 },
  { questions: tenFAndBTimes, id: 10 },
  { questions: elevenFAndBTimes, id: 11 },
  { questions: twelveFAndBTimes, id: 12 },
];

export const getTwoFiveTenQuestionSet = (): Question[] => {
  const questions = new Set<Question>();
  // Add min num of times table questions
  twoFiveTensTimes.forEach((timesTable) => {
    getRandomSubArrayFromArray(timesTable.questions, timesTable.num).forEach(
      (question) => questions.add(question)
    );
  });

  // convert set to array and shuffle
  return shuffle(Array.from(questions));
};

export const getRandomQuestionSet = (): Question[] => {
  const questions = new Set<Question>();
  // Add min num of times table questions
  collection.forEach((timesTable) => {
    getRandomSubArrayFromArray(timesTable.questions, timesTable.num).forEach(
      (question) => questions.add(question)
    );
  });

  // convert set to array and shuffle
  return shuffle(Array.from(questions));
};

export const getSelectionQuestionSet = (tables: number[]): Question[] => {
  // Determine the number of questions per table
  const numTables = tables.length;
  const questionsPerTable = Math.floor(25 / numTables);
  const numQuestionsPerTable = Math.min(22, questionsPerTable);

  // Array to store the generated questions
  let questions: Question[] = [];

  // Generate questions for each table
  const selection = selectTimes.filter((table) => {
    return tables.includes(table.id);
  });

  selection.forEach((table) => {
    getRandomSubArrayFromArray(table.questions, numQuestionsPerTable).forEach(
      (question) => questions.push(question)
    );
  });

  // If 25 isn't perfectly divisible by numTables, then add some extra questions
  while (questions.length < 25) {
    const table = selection[Math.floor(Math.random() * selection.length)];
    questions.push(getRandomFromArray(table.questions) as Question);
  }

  // convert set to array and shuffle
  return shuffle(Array.from(questions));
};
