/*
 * Thompson's MTC
 * Copyright © 2022 Vanilla Gorilla Limited
 * index.tsx - components for the app's navigation
 */

import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import HomeScreen from "../components/HomeScreen";
import TestScreen from "../components/TestScreen";
import ResultsScreen from "../components/ResultsScreen";
import { RootStackParamList } from "./types";
import TimeScreen from "../components/TimeScreen";
import SelectScreen from "../components/SelectScreen";

export default function Navigation() {
  return (
    <NavigationContainer>
      <StackNavigator />
    </NavigationContainer>
  );
}

const RootStack = createStackNavigator<RootStackParamList>();

function StackNavigator() {
  const headerOptions = (title: string) => ({
    title: title,
    headerBackTitleVisible: false,
  });

  return (
    <RootStack.Navigator
      initialRouteName="Home"
      screenOptions={{ gestureEnabled: false }}
    >
      <RootStack.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: "Thompson's MTC",
        }}
      />

      <RootStack.Screen
        name="Select"
        component={SelectScreen}
        options={{
          title: "Thompson's MTC",
        }}
      />

      <RootStack.Screen
        name="Time"
        component={TimeScreen}
        options={{
          title: "Thompson's MTC",
        }}
      />

      <RootStack.Screen
        name="Test"
        component={TestScreen}
        options={({ route }) =>
          headerOptions(`${route.params.time} Seconds Practice`)
        }
      />

      <RootStack.Screen
        name="Results"
        component={ResultsScreen}
        options={headerOptions("Results")}
      />
    </RootStack.Navigator>
  );
}
