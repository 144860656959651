/*
 * 1-Minute Maths
 * Copyright © 2021 - 2022 White Rose Maths
 * Numpad.tsx - Numpad view for the app.
 */

import React from 'react';
import { useCallback, useEffect } from 'react';
import {
  TouchableOpacity, View, Text, StyleSheet, Image, Platform
} from 'react-native';
import { moderateScale } from 'react-native-size-matters';


const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9'] as const;
type NumberCharacter = typeof numbers[number] | '0';

interface NumpadProps {
  handleInputEvent(input: NumberCharacter): void;
  handleBackspaceEvent(): void;
  handleConfirmEvent(): void;
  disabled: boolean;
}

const Numpad = ({ handleInputEvent, handleBackspaceEvent, handleConfirmEvent, disabled }: NumpadProps) => {

  const handleKeyboardDown = (
    event: KeyboardEvent,
    disabled: boolean,
    handleInputEvent: NumpadProps['handleInputEvent'],
    handleBackspaceEvent: NumpadProps['handleBackspaceEvent'],
    handleConfirmEvent: NumpadProps['handleConfirmEvent']
  ) => {
    if (event.code === 'Tab') {
      event.stopPropagation();
      event.preventDefault();
      return;
    }
    if (disabled) {
      return;
    }
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleConfirmEvent();
      event.stopPropagation();
      event.preventDefault();
    } else if (event.code === 'Backspace' || event.code === 'Delete') {
      handleBackspaceEvent();
      event.stopPropagation();
      event.preventDefault();
    } else {
      let value: NumberCharacter;
      switch (event.code) {
        case 'Digit1':
        case 'Numpad1':
        {
          value = '1';
          break;
        }
        case 'Digit2':
        case 'Numpad2':
        {
          value = '2';
          break;
        }
        case 'Digit3':
        case 'Numpad3':
        {
          value = '3';
          break;
        }
        case 'Digit4':
        case 'Numpad4':
        {
          value = '4';
          break;
        }
        case 'Digit5':
        case 'Numpad5':
        {
          value = '5';
          break;
        }
        case 'Digit6':
        case 'Numpad6':
        {
          value = '6';
          break;
        }
        case 'Digit7':
        case 'Numpad7':
        {
          value = '7';
          break;
        }
        case 'Digit8':
        case 'Numpad8':
        {
          value = '8';
          break;
        }
        case 'Digit9':
        case 'Numpad9':
        {
          value = '9';
          break;
        }
        case 'Digit0':
        case 'Numpad0':
        {
          value = '0';
          break;
        }
        default: {
          return;
        }
      }
  
      handleInputEvent(value);
    }
  };

  const onKeyboardDown = useCallback(
    (event: KeyboardEvent) =>
      handleKeyboardDown(event, disabled, handleInputEvent, handleBackspaceEvent, handleConfirmEvent),
    [disabled, handleInputEvent, handleBackspaceEvent, handleConfirmEvent]
  );

  useEffect(() => {
    if(Platform.OS === 'web'){
      document.addEventListener('keydown', onKeyboardDown);
    }
    
    return () => {
      if(Platform.OS === 'web'){
        document.removeEventListener('keydown', onKeyboardDown);
      }
    }
  }, [onKeyboardDown]);

  
  return (
    <View style={styles.pad}>
      {numbers.map((value, index) => {
        return (
          <TouchableOpacity
            key={index}
            style={styles.button}
            onPress={() => handleInputEvent(value)}
            disabled={disabled}
          >
            <Text style={styles.buttonText} allowFontScaling={false}>{value}</Text>
          </TouchableOpacity>
        );
      })}
      <TouchableOpacity
        key="backspace"
        style={styles.button}
        onPress={handleBackspaceEvent}
        disabled={disabled}
      >
        <Text style={styles.buttonText} allowFontScaling={false}>Del</Text>
      </TouchableOpacity>

      <TouchableOpacity
        key="zero"
        style={styles.button}
        onPress={() => handleInputEvent('0')}
        disabled={disabled}
      >
        <Text style={styles.buttonText} allowFontScaling={false}>0</Text>
      </TouchableOpacity>

      <TouchableOpacity
        key="confirm"
        style={styles.button}
        onPress={handleConfirmEvent}
        disabled={disabled}
        hasTVPreferredFocus={true}
      >
        <Text style={styles.buttonText} allowFontScaling={false}>Enter</Text>
      </TouchableOpacity>
    </View>
  );
};

export default Numpad;

const styles = StyleSheet.create({
  pad: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    height: '90%',
    width: '74%',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    alignSelf: 'flex-start',
    borderLeftWidth: moderateScale(10),
    borderRightWidth: moderateScale(10),
    borderColor: 'white'
  },

  button: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '33%',
    height: '25%',
    backgroundColor: '#dee0e2',
    borderColor: 'white',
    borderWidth: moderateScale(5),
  },

  buttonText: {
    color: '#575756',
    fontSize: moderateScale(22),
    textAlign: 'center',
  },

});
